<template>
  <v-card-text style="color: #000">
    <div class="mb-4">
      หากกิจการต้องการเปลี่ยนจำนวนสัดส่วนของผู้ถือหุ้น
      ซึ่งจะเกี่ยวข้องกับการเปลี่ยนจำนวนหุ้นที่ถือครอง
      หรือเปลี่ยนอัตราส่วนที่แบ่งกันระหว่างผู้ถือหุ้น
      โดยการเปลี่ยนจำนวนของผู้ถือหุ้น จะต้องเกิดจากความสมัครใจของทั้ง 2 ฝ่าย
      ระหว่างผู้โอนหุ้นกับผู้รับโอนหุ้น
    </div>

    <div>
      ทั้งนี้ หากกิจการไม่ได้มีข้อบังคับในการโอนหุ้น
      จะต้องทำเป็นหนังสือระหว่างผู้โอนและผู้รับโอน โดยมีพยานอย่างน้อย 1 คน
      ลงชื่อรับรอง และต้องทำตามแบบของสัญญาโอนหุ้น
      ไม่เช่นนั้นผลของการโอนหุ้นจะถือเป็นโมฆะ
      และเมื่อมีการบันทึกการโอนหุ้นในทะเบียนหุ้นของกิจการเป็นข้อมูลล่าสุดแล้ว
      การโอนหุ้นจึงจะถือว่าสมบูรณ์ตามกฎหมาย
      โดยไม่ต้องมาจดทะเบียนที่กรมพัฒนาธุรกิจการค้า
    </div>
  </v-card-text>
</template>

<script>
export default {
  name: "ChangeShareholderEquitySummaryComponent",
};
</script>
