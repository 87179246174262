<template>
  <v-stepper v-model="currentStep" vertical>
    <v-stepper-step color="#ea6b0d" :complete="isCompleteStep(1)" step="1">
      กิจการของคุณต้องการเปลี่ยนจำนวนสัดส่วนของผู้ถือหุ้น
      หรือเพิ่ม-ถอนรายชื่อผู้ถือหุ้น
    </v-stepper-step>

    <v-stepper-content step="1">
      <v-container>
        <v-card class="mb-4">
          <v-card-text>
            <v-row>
              <v-col>
                <v-radio-group
                  row
                  :value="formOptions.isChangeShareholderEquity"
                  @change="onShareholderChange"
                >
                  <v-radio color="#ea6b0d" :value="true">
                    <template v-slot:label>
                      <div>เปลี่ยนจำนวนสัดส่วนของผู้ถือหุ้น</div>
                    </template>
                  </v-radio>
                  <v-radio color="#ea6b0d" :value="false">
                    <template v-slot:label>
                      <div>ต้องการเพิ่ม-ถอนรายชื่อผู้ถือหุ้น</div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>

      <v-btn
        color="#ea6b0d"
        class="mr-3"
        :disabled="formOptions.isChangeShareholderEquity === null"
        style="color: #fff"
        @click="nextStep()"
      >
        ยืนยัน
      </v-btn>
      <v-btn
        color="#ea6b0d"
        text
        :disabled="isBackButtonDisabled"
        @click="prevStep()"
      >
        ย้อนกลับ
      </v-btn>
    </v-stepper-content>

    <v-stepper-step color="#ea6b0d" :complete="isCompleteStep(2)" step="2">
      เลือกคำตอบที่เหมาะสมกับคุณ
    </v-stepper-step>

    <v-stepper-content step="2">
      <v-container>
        <v-card class="mb-4">
          <v-card-text>
            <div v-for="item of forms" :key="item.title">
              {{ item.title }}
              <v-radio-group
                row
                :value="item.answer"
                @change="(value) => onFormValueChange(value, item.position)"
              >
                <v-radio color="#ea6b0d" :value="true">
                  <template v-slot:label>
                    <div>ใช่</div>
                  </template>
                </v-radio>
                <v-radio color="#ea6b0d" :value="false">
                  <template v-slot:label>
                    <div>ไม่ใช่</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
          </v-card-text>
        </v-card>
      </v-container>

      <v-btn
        color="#ea6b0d"
        style="color: #fff"
        class="mr-3"
        :disabled="isGetSummaryButtonDisabled"
        @click="nextStep()"
      >
        ยืนยัน
      </v-btn>
      <v-btn
        color="#ea6b0d"
        text
        :disabled="isBackButtonDisabled"
        @click="prevStep()"
      >
        ย้อนกลับ
      </v-btn>
    </v-stepper-content>

    <v-stepper-step color="#ea6b0d" :complete="isCompleteStep(2)" step="3">
      สรุปผล
    </v-stepper-step>

    <v-stepper-content step="3">
      <v-container>
        <v-card class="mb-12">
          <v-card-text>
            <v-card color="#ea6b0d">
              <v-card-text class="display-1" style="color: white">
                กิจการของคุณ
                <span class="display-1" style="font-weight: bold">{{
                  getResult
                }}</span></v-card-text
              >
            </v-card>
          </v-card-text>
          <component :is="getSummaryResultComponent" />
          <v-card-text style="color: #ea6b0d">
            แต่ทางออกที่ดีที่สุดเพื่อให้ธุรกิจดำเนินการต่อไปได้อย่างราบรื่น
            ไม่ต้องเกิดการเปลี่ยนแปลงผู้ถือหุ้น สามารถหาคำตอบได้จากบทความ
            <span
              ><a
                href="https://inflowaccount.co.th/good-partner/"
                target="blank"
                >“หุ้นส่วน แบบไหนที่ควรมี ก่อนตัดสินใจจดทะเบียนบริษัท”</a
              ></span
            >
            หากต้องการคำแนะนำเรื่องบัญชี-ภาษี สามารถทักแชทได้ที่
            <span
              ><a
                href="https://page.line.me/361ykfds?openQrModal=true"
                target="blank"
                >@inflowaccount</a
              ></span
            >
            หรือโทร. <a target="blank" href="tel:0934142885">093-4142885</a>
            เพราะทุกปัญหาเรื่องภาษีใกล้ตัวคุณมากกว่าที่คุณรู้
          </v-card-text>
        </v-card>
      </v-container>

      <v-btn
        color="#ea6b0d"
        style="color: #fff"
        @click="onRestartAssessment"
        class="mr-3"
      >
        เริ่มทำแบบทดสอบใหม่
      </v-btn>
      <v-btn
        color="#ea6b0d"
        text
        :disabled="isBackButtonDisabled"
        @click="prevStep()"
      >
        ย้อนกลับ
      </v-btn>
    </v-stepper-content>
  </v-stepper>
</template>
<script>
import ChangeShareholderEquitySummaryComponent from "@/components/formStepper/formResult/changeShareholderEquitySummaryCard/index";
import ChangeShareholderSummaryComponent from "@/components/formStepper/formResult/changeShareholderSummaryCard/index";

export default {
  name: "FormStepperComponent",
  components: {
    ChangeShareholderEquitySummaryComponent,
    ChangeShareholderSummaryComponent,
  },
  props: {
    formOptions: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isMinStep() {
      return this.currentStep === this.minStep;
    },
    isMaxStep() {
      return this.currentStep === this.maxStep;
    },
    isBackButtonDisabled() {
      return this.isMinStep;
    },
    forms() {
      return this.formOptions.formQuestionLists.filter((form) => {
        return (
          form.isChangeShareholderQuestionGroup ===
          this.formOptions.isChangeShareholderEquity
        );
      });
    },
    getResult() {
      const canChangeShareholder = this.forms.every(
        (form) => form.answer === true
      );
      const ansText = canChangeShareholder
        ? "สามารถเปลี่ยนแปลงผู้ถือหุ้นได้"
        : "ยังไม่สามารถเปลี่ยนแปลงผู้ถือหุ้นได้";
      return ansText;
    },
    getSummaryResultComponent() {
      return this.formOptions.isChangeShareholderEquity
        ? "ChangeShareholderEquitySummaryComponent"
        : "ChangeShareholderSummaryComponent";
    },
    isGetSummaryButtonDisabled() {
      const form = this.forms;
      return form.some((question) => question.answer === null);
    },
  },
  data: () => ({
    currentStep: 1,
    maxStep: 3,
    minStep: 1,
  }),
  methods: {
    onFormValueChange(value, position) {
      this.$emit("onFormValueChange", { value, position });
    },
    onShareholderChange(value) {
      this.$emit("onShareholderChange", value);
    },
    isCompleteStep(stepIndex) {
      return this.currentStep > stepIndex;
    },
    prevStep() {
      if (!this.isMinStep) {
        this.currentStep--;
      }
    },
    nextStep() {
      if (!this.isMaxStep) {
        this.currentStep++;
      }
    },
    onRestartAssessment() {
      this.currentStep = 1;
      this.$emit("onRestartForm");
    },
  },
};
</script>

<style scoped>
* {
  font-size: 18px;
}
</style>
