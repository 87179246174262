<template>
  <div class="home" style="height: 100%; display: flex; align-items: center">
    <home-component />
  </div>
</template>

<script>
import HomeComponent from "../components/HomeComponent";

export default {
  name: "HomeView",

  components: {
    HomeComponent,
  },
};
</script>
