<template>
  <v-container>
    <v-row>
      <v-col class="mb-2 text-center" cols="12">
        <h1 class="display-2 font-weight-bold mb-3">
          แบบประเมิน กิจการของคุณสามารถ
        </h1>

        <div class="display-1 font-weight-bold color-primary">
          เปลี่ยนแปลงผู้ถือหุ้นได้หรือไม่
        </div>
      </v-col>

      <v-col class="mb-5" cols="12">
        <v-row justify="center">
          <v-col cols="12" sm="8">
            <v-container>
              <v-row>
                <v-col>
                  <FormStepper
                    :form-options="formOptions"
                    @onShareholderChange="updateShareholderValue"
                    @onFormValueChange="updateFormValue"
                    @onRestartForm="resetFormValue"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormStepper from "@/components/formStepper/index";
export default {
  name: "HomeComponent",
  components: { FormStepper },
  computed: {
    formOptions() {
      return {
        isChangeShareholderEquity: this.isChangeShareholderEquity,
        formQuestionLists: this.formQuestionLists,
      };
    },
  },
  data: () => ({
    isChangeShareholderEquity: null,
    formQuestionLists: [
      {
        position: 0,
        title:
          "กรณีเปลี่ยนสัดส่วนจำนวนหุ้นที่ถือครอง การโอนหุ้นเกิดจากความสมัครใจของทั้ง 2 ฝ่ายแล้วใช่หรือไม่",
        answer: null,
        isChangeShareholderQuestionGroup: true,
      },
      {
        position: 1,
        title:
          "หากกิจการไม่ได้มีข้อบังคับในการโอนหุ้น ได้ทำหนังสือเอกสารซื้อขายและโอนหุ้น ที่มีพยานรับรองเพื่อเป็นการยืนยันแล้วใช่หรือไม่",
        answer: null,
        isChangeShareholderQuestionGroup: true,
      },
      {
        position: 2,
        title:
          "กรณีที่มีการเพิ่ม-ถอนรายชื่อผู้ถือหุ้น ได้มีการตกลงราคาในการซื้อหุ้นของผู้ถอนหุ้นออก เพื่อแจ้งให้ผู้บริหารทราบแล้วใช่หรือไม่",
        answer: null,
        isChangeShareholderQuestionGroup: false,
      },
      {
        position: 3,
        title: "กิจการได้ทำการหาบุคคลอื่นมาถือหุ้นแทนแล้วใช่หรือไม่",
        answer: null,
        isChangeShareholderQuestionGroup: false,
      },
    ],
  }),
  methods: {
    updateShareholderValue(value) {
      this.isChangeShareholderEquity = value;
    },
    updateFormValue(formPayload) {
      this.formQuestionLists[formPayload.position].answer = formPayload.value;
    },
    resetFormValue() {
      this.isChangeShareholderEquity = null;
      this.formQuestionLists.forEach((item) => {
        item.answer = null;
      });
    },
  },
};
</script>

<style scoped>
.color-primary {
  color: #ea6b0d;
}
</style>
