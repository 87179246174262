<template>
  <v-card-text style="color: #000">
    <div class="mb-4">
      หากกิจการต้องการเพิ่ม-ถอนรายชื่อผู้ถือหุ้น คือการเพิ่มรายชื่อผู้ถือหุ้น
      หรือถอนรายชื่อผู้ถือหุ้นออกจากบริษัท
      เป็นการซื้อ-ขายหุ้นที่มีอยู่ภายในบริษัท ซึ่งก่อนที่จะเริ่มทำการถอดหุ้นออก
      ต้องตกลงราคาในการซื้อหุ้นของผู้ที่ถอดหุ้น
      เพื่อเป็นการแจ้งให้ผู้บริหารได้รับทราบและหาบุคคลอื่นมาถือหุ้นแทน
      แล้วคืนเงินที่ลงทุนพร้อมผลประโยชน์ค่าชดเชยให้กับหุ้นส่วนที่ถอนหุ้น
    </div>
    <div>
      โดยหลังจากมีการเปลี่ยนแปลงรายชื่อผู้ถือหุ้นแล้ว
      คือเมื่อผู้ถือหุ้นได้ทำการซื้อ-ขายหุ้นเสร็จสิ้นแล้ว
      และกรรมการได้ลงแก้ไขรายการในสมุดทะเบียนหุ้นของบริษัทแล้ว
      จะถือเป็นอันเสร็จสมบูรณ์โดยไม่ต้องแจ้งกับหน่วยงานใดๆ
    </div>
  </v-card-text>
</template>

<script>
export default {
  name: "ChangeShareholderSummaryComponent",
};
</script>
